<template>
  <div>
    <!--入库单编辑框-->
    <znl-dialog
      :title="title"
      :visible="editvisible"
      subhead="(橙色底为必填项)"
      :height="dialogbox.height"
      :width="dialogbox.width"
      class="dialog-znl-stkinedit"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      @close="
        (val) => {
          $emit('close', val), onResetStkin();
        }
      "
    >
      <znl-gridmodule
        ref="flexGrid"
        gridtype="action"
        height="445px"
        :max-height="445"
        class="stkinedit-min-grid-box m-border-box"
        :has-znl-btns="false"
        layout-type="other"
        :from-height="150"
        :from-btns="fromBtns"
        element-loading-text="正在保存,请稍等..."
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-alter-ver="true"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :on-init="onInit"
        :loading="loading"
        :qtyAbs="true"
        :qtyClear="true"
        from="StkIn"
        :users="$store.state.users"
        :on-before-add-row="onBeforeAddRow"
        :on-delete-checked-rows="onDeletedRows"
        :on-deleted-checked-rows="
          () => {
            CalcPay(false);
          }
        "
        :showAddRowButton="showAddRowButton"
        :showAddRowRightMenu="showAddRowRightMenu"
        @click.native.stop="autocompleteShow = false"
        @page-loading="
          (d) => {
            $emit('page-loading', d);
          }
        "
        @edit:cell-ended="onCellEdited"
        :is-multi-rows-check="true"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style="border: 1px solid #d8d8d8">
          <el-row
            class="formContainer"
            type="flex"
            justify="space-around"
            style="
              border-bottom: 1px solid #e6e6e6;
              height: 40px;
              line-height: 40px;
            "
          >
            <el-col :span="12" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="znlAutocomplete"
                    @change="autocompleteChange"
                    custom-item="my-item-zh"
                    :fetch-suggestions="querySearch"
                    @select="suggestionsSelect"
                    :disabled="fromPurchaseOrderOpen"
                    placeholder="输入供应商简称或拼音字母,并按回车键查询"
                    size="mini"
                    layout="left"
                    :is-must-fill="this.IsSupplierNameRequired"
                    :title="this.SupplierNameTitle"
                    :border="true"
                    width="100%"
                    tabindex="1"
                    v-model="StkIn.SupplierName"
                    :on-icon-click="SearchCompany"
                    @keyup.enter.native="SearchCompany"
                    type="text"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-edit_btn_ic"
                      @click="SearchCompany"
                    ></i>
                  </znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-preboder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="!IsManual || !StkIn.IsAdd"
                    placeholder="默认自动生成 "
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="单据编号："
                    width="80%"
                    :border="true"
                    v-model="StkIn.BillNo"
                    :on-icon-click="isAllow && onBillIconClick"
                    type="text"
                  ></znl-input>

                  <el-checkbox
                    width="20%"
                    size="small"
                    v-model="IsManual"
                    :disabled="!StkIn.IsAdd"
                    >手动</el-checkbox
                  >
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    ref="ReceiveTime"
                    layout="left"
                    form-type="datepicker"
                    :readonly="false"
                    :disabled="false"
                    :is-must-fill="true"
                    v-model="StkIn.ReceiveTime"
                    width="100%"
                    tabindex="6"
                    :border="true"
                    :editable="false"
                    title="*入库日期："
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    size="mini"
                    title-width="70px"
                    ref="ReceiveUserID"
                    width="100%"
                    layout="left"
                    form-type="select"
                    placeholder="请选择"
                    title="*入库员："
                    popper-class="popperClass"
                    :is-must-fill="true"
                    :border="true"
                    tabindex="7"
                    :select-options="selectOptionsAllUserURL"
                    v-model="StkIn.ReceiveUserID"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row
                class="form_Row_W100"
                type="flex"
                style="margin-bottom: 2px"
              >
                <el-col>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="Address"
                        title-width="70px"
                        layout="left"
                        form-type="input"
                        width="100%"
                        placeholder="供应商地址 / 收货地址"
                        popper-class="popperClass"
                        title="地址："
                        :disabled="false"
                        :clearable="true"
                        :border="true"
                        tabindex="9"
                        :allow-create="true"
                        :is-must-fill="false"
                        @blur="changeAddressInputHandler"
                        :select-options="selectOptionsAddress"
                        v-model="StkIn.Address"
                        :filterable="true"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="12">
                      <znl-input
                        ref="Contact"
                        title-width="70px"
                        form-type="input"
                        :disabled="fromPurchaseOrderOpen"
                        placeholder
                        layout="left"
                        :is-must-fill="false"
                        :border="true"
                        title="联系人："
                        width="100%"
                        tabindex="3"
                        v-model="StkIn.Contact"
                        type="text"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="Telephonenumb"
                        title-width="70px"
                        :border="true"
                        form-type="input"
                        :disabled="fromPurchaseOrderOpen"
                        placeholder
                        layout="left"
                        :is-must-fill="false"
                        title="联系电话："
                        width="100%"
                        tabindex="4"
                        v-model="StkIn.Telephone"
                        type="text"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <znl-input
                        title-width="70px"
                        ref="PurCompany"
                        layout="left"
                        form-type="select"
                        :disabled="false"
                        width="100%"
                        placeholder="请选择"
                        popper-class="popperClass"
                        title="采购公司："
                        :border="true"
                        :clearable="true"
                        tabindex="5"
                        :is-must-fill="false"
                        :select-options="selectOptionsUserSubCompany"
                        v-model="StkIn.PurCompany"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>

            <el-col
              :span="20"
              class="min-boder-box"
              style="border-bottom: none"
            >
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="付款方式："
                    :clearable="true"
                    :border="true"
                    tabindex="9"
                    @change="paymentTypeChange"
                    :disabled="false"
                    :select-options="selectOptionsPaymentTypeOptions"
                    v-model="StkIn.PaymentType"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    ref="CurrencyCode"
                    form-type="select"
                    :disabled="false"
                    width="70%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="*币 种："
                    :border="true"
                    tabindex="20"
                    :is-must-fill="true"
                    @change="CurrencyCodeChange"
                    :select-options="selectOptionsCurrencyCode"
                    v-model="StkIn.CurrencyCode"
                  ></znl-input>

                  <znl-input
                    form-type="input"
                    :disabled="this.IsExchangeRateRMB"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="false"
                    title
                    width="28%"
                    tabindex="21"
                    v-model="StkIn.ExchangeRate"
                    type="text"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    ref="StkInTaxRate"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="税 点："
                    :border="true"
                    :is-mut-fill="false"
                    tabindex="14"
                    @change="CalcTaxRate"
                    :select-options="selectOptionsTaxRate"
                    v-model="StkIn.TaxRate"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    layout="left"
                    form-type="datepicker"
                    :is-must-fill="false"
                    v-model="StkIn.ExpectPayDate"
                    width="100%"
                    :border="true"
                    placeholder="预付款日期"
                    title="预付日期："
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="disabledInput"
                    :readonly="false"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="应付额："
                    :border="true"
                    tabindex="11"
                    width="100%"
                    v-model="StkIn.PayablesAmount"
                    type="text"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    layout="left"
                    placeholder="应到货日期"
                    form-type="datepicker"
                    :border="true"
                    :is-must-fill="false"
                    v-model="StkIn.NeedDeliveryDate"
                    width="100%"
                    title="应到日期："
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    title-width="70px"
                    v-if="systemConfig.hasFinance && hasResMain('BatchPay')"
                    layout="left"
                    form-type="select"
                    width="100%"
                    placeholder="请选择"
                    popper-class="popperClass"
                    title="付款账号："
                    :clearable="true"
                    :is-must-ill="false"
                    :border="true"
                    tabindex="8"
                    :disabled="!StkIn.IsAdd"
                    :select-options="bankAccountNameList"
                    v-model="StkIn.CBGUID"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    form-type="input"
                    ref="PaidAmount"
                    placeholder
                    size="mini"
                    layout="left"
                    :border="true"
                    :is-must-fill="false"
                    :disabled="!StkIn.IsAdd || !hasResMain('BatchPay')"
                    title="实付额："
                    width="100%"
                    tabindex="12"
                    v-model="StkIn.PaidAmount"
                    type="text"
                  ></znl-input>

                  <znl-input
                    title-width="70px"
                    ref="DiscountAmount"
                    form-type="input"
                    size="mini"
                    layout="left"
                    :disabled="fromPurchaseOrderOpen"
                    :is-must-fill="false"
                    title="折扣额："
                    width="100%"
                    :border="true"
                    tabindex="10"
                    @change="discountChange"
                    v-model="StkIn.DiscountAmount"
                    type="text"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            type="flex"
            justify="space-around"
          >
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    title-width="70px"
                    class="m-txt-remark"
                    form-type="input"
                    placeholder
                    size="mini"
                    layout="left"
                    :is-must-fill="false"
                    title="入库备注："
                    :border="true"
                    :rows="2"
                    tabindex="5"
                    width="100%"
                    v-model="StkIn.Remark"
                    type="textarea"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100">
                <el-col :span="8"></el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div slot="from-content">
          <!-- <el-checkbox
            size="small"
            v-model="useLoanService"
            @change="onLoanServiceChange"
            v-if="hasLoanService && hasRes('LoanService')"
            >使用月结服务</el-checkbox
          >

          <span style="margin-right: 8px; color: #EE7700; cursor:pointer;"
            title="什么是月结服务？"
            v-if="hasLoanService && hasRes('LoanService')"
            @click="() => { monthlyServiceIntroVisible = true; }">
            <i class="iconfont icon-table_error_ic"></i> </span> -->
          <a
            @click="onImportDeclaration"
            style="color: orange; margin-right: 5px"
            >进口报关>></a
          >
          <a
            href="https://www.bom.ai/escrow"
            target="_blank"
            style="color: orange; margin-right: 5px"
            >申请担保交易>></a
          >

          <el-checkbox
            size="small"
            @change="onContinuousRecordValue()"
            v-model="IsContinuousRecord"
            >连续录单模式</el-checkbox
          >
          <el-checkbox
            size="small"
            v-model="IsPrint"
            @change="onPrintValue()"
            v-if="hasResMain('Print')"
            >保存后并打印</el-checkbox
          >

          <znl-button
            style-type="mac"
            :height="22"
            v-if="hasResMain('Print')"
            type="multiple"
          >
            <znl-button-menu @click="onPrint">
              <i class="iconfont icon-print_btn_ic"></i>
              <span>打印</span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>

          <znl-button
            :height="22"
            @click="stkinSave"
            v-if="hasResMain('Add') || hasResMain('Edit') || hasResMain('In')"
            :style-type="useLoanService ? 'main' : 'minor'"
            :disabled="isDisabled"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>{{ useLoanService ? "保存并申请月结服务" : "保存" }}</span>
          </znl-button>

          <znl-button
            :height="22"
            @click="stkinSaveAndStorage"
            v-if="hasResMain('In')"
            style-type="main"
            v-show="!useLoanService"
            :disabled="isDisabled"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存并入库</span>
          </znl-button>

          <!-- <znl-button :height='22' @click="() => {isSwitchToSCM = true, stkinSave()}" v-if='hasRes("Print")' style="margin-left:5px">
             <span><i class="iconfont icon-save_btn_ic"></i>保存并加入报关单</span>
          </znl-button>-->
          <znl-print
            :visible.sync="znlPrintVisible"
            :printInfo="printInfo"
            :loading="printLoading"
            :init-param="false"
            :is-get-default-param="true"
            print-type="STK_In"
            title="入库单打印设置"
            @set-print-param="setPrintParam"
            @confirm-print="confirmPrint"
          ></znl-print>
        </div>
      </znl-gridmodule>
      <div style="margin-top: 10px">
        <stock-query
          @row-dbl-click="
            (row) => {
              addRowToItemSource([row]);
            }
          "
          height="205px"
        ></stock-query>
      </div>
    </znl-dialog>

    <znl-dialog
      title="从Excel粘贴"
      :visible="isBulkAddShow"
      height="300px"
      width="900px"
      class="pasteDataAcquisitionLog"
      :close-on-click-modal="false"
      :is-footer-show="false"
      @close="isBulkAddShow = false"
    >
      <pasteDataAcquisition
        slot="znl-dialog"
        :columns="setExcelColumns"
        @add-excel-data="addExcelData"
      ></pasteDataAcquisition>
    </znl-dialog>

    <d-company-contact-select
      :visible="dialogCompanySelectVisible"
      companyType="2,3"
      title="选择供应商"
      :searchModel="SelectSearchModel"
      @close="
        () => {
          dialogCompanySelectVisible = false;
        }
      "
      @update:visible="
        (val) => {
          dialogCompanySelectVisible = val;
        }
      "
      @confirm="companyChecked"
    ></d-company-contact-select>

    <dialog-stock-select
      slot="znl-dialog"
      :visible="StkStockCheckVisible"
      v-if="StkStockCheckVisible"
      @confirm="setGridData"
      @close="
        () => {
          StkStockCheckVisible = false;
        }
      "
    ></dialog-stock-select>

    <!-- 月结服务介绍 -->
    <monthly-service-intro
      v-if="monthlyServiceIntroVisible"
      @close="
        () => {
          monthlyServiceIntroVisible = false;
        }
      "
    ></monthly-service-intro>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { renderRequiredColumn } from "~assets/scripts/methods/common";
import {
  stockInToSCM,
  openLoanService,
  openSCM,
} from "~/assets/scripts/methods/clientTabPage";

import dCompanyContactSelect from "../Crm/DCompanyContactSelect";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { getabsleft, getabstop } from "~assets/scripts/utils/dom";
import autocomplete from "@c_common/znlGrid/autocomplete";
import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import pasteDataAcquisition from "@c_common/znlGrid/commonComponents/pasteDataAcquisition";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import { printInfoMixin } from "@c_common/znlGrid/commonComponents/common";
import StockQuery from "@c_modules/Control/StockQuery";
import MonthlyServiceIntro from "@c_modules/Pub/MonthlyServiceIntro.vue";

const CONFIG = {
  PK: "StkInLineID",
  configURL: "StkInLine/GetConfig",
  StkInSaveAndStorageURL: "StkInAdd/NewAdd", // 保存
  StkInSearchById: "StkInAdd/Search",
  GetStkInlineBystkguid: "StkInAdd/GetInlineByStkGUID",
  StkInSearchByLineguid: "StkInAdd/GetInlineByStkInGUIDS",
  SearchByPurchaseGUID: "StkInAdd/SearchByPurchaseGUID", // 根据订货guid获取入库单
  SearchByPurchaseGUIDPL: "StkInAdd/SearchByPurchaseGUIDPL", // 批量采购入库
  bomLineGotoStkInURL: "BomDetailLine/BomLineGotoStkIn",
  GetStkVMIByGUIDS: "StockVMI/GetStkVMIByGUIDS", // 供应商库存
  configJsonURL: "StkIn/GetConfig",
  SearchByBOMGUIDS: "SalesOrderAdd/SearchByBOMGUIDS",
  GetPrice: "StkInAdd/GetPrice", // 获取上次的价格
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  CreateByPOGUID: "StkInAdd/CreateByPOGUID",
  AddInfoCollectLogUrl: "InfoCollection/AddLog", //添加点击记录日志
  GetCompanySetupUrl: "CompanyParamSetup/GetValue", //获取公司配置
};

export default {
  name: "StkInAdd",
  config: CONFIG,
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
    printInfoMixin
  ],
  components: {
    dCompanyContactSelect,
    autocomplete,
    pasteDataAcquisition,
    ZnlPrint,
    DialogStockSelect,
    StockQuery,
    MonthlyServiceIntro,
  },
  data() {
    return {
      warehouses: [],
      hasOperationColumn: {
        StockQuery: false,
        StockVMIMini: false,
        PastInquire: false,
        StkOutByModel: false,
        HisOfferPriceByModel: false,
      },
      selectOptionsAddress: [],
      IsContinuousRecord: false,
      clientSelectLoading: false,
      dialogLoading: true,
      isBulkAddShow: false,
      autocompleteShow: false,
      StkStockCheckVisible: false,
      autocompleteSearchData: "",
      startTime: 0,
      dialogCompanySelectVisible: false,
      loading: false,
      InTypes: "", // 1:客户，2：供应商，3:两者都是的公司
      StkIn: {
        // 入库单对象
        BillNo: "", // 采购单号
        ReceiveTime: new Date().toLocaleDateString(), // 入库日期
        ReceiveUserID: this.$store.state.accountInfo.UserID, // 采购员ID
        Receiver: this.$store.state.accountInfo.UserName, // 采购员
        PayablesAmount: "", // 应付金额
        SupplierName: "", // 供应商
        Contact: "", // 联系人
        PaidAmount: "", // 实付金额
        Address: "", // 地址
        Telephone: "", // 联系电话
        PaymentType: "", // 付款方式
        TaxRate: null, // 税点
        Remark: "", // 备注
        CurrencyCode: "RMB", // 币种
        ExchangeRate: "1", // 汇率
        DiscountAmount: null, // 折扣金额
        IsAdd: true, // 是否新增
        isAllowStorge: true, // 是否为保存并入库
        LineList: [], // 保存入库详情明细行
        PDID: "",
        CBGUID: "", // 收款账号,
        ExistPurchaseOrder: "",
        StkInGUID: "", // 入库单ID
        StkInID: "",
        ExpectPayDate: "",
        NeedDeliveryDate: "",
        NewSupplierLabel: null, // 当为新的供应商时，默认为该供应商添加的标签
      },
      SelectSearchModel: {
        CompanyName: "",
      },
      IsTakeOutPrint: false, // 是否带出上次价格
      IsSupplierNameRequired: false, // 供应商名称是否必填
      SupplierNameTitle: " 供应商：",
      paramTax: null,
      paramQtyType: "",
      paramQtyUnit: "",
      ParamJosn: [],
      ParamJosnLine: [],
      IsPrint: true,
      CurrencyCodeOptions: [], // 币种
      BuyerOptions: [], // 采购员列表
      resourceList: [],
      mainResourceList: [], // 配置在主单的权限
      // 绑定列表中的下拉框
      disabledInput: true, // 禁用采购单
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      pickerOptions0: {},
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      rowID: 0,
      StkInLineID: -1,
      summaryColumns: [],
      setExcelColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      role: "1",
      isReadonly: false,
      showAddRowButton: true,
      showAddRowRightMenu: true,
      isPopup: false,
      fromPurchaseOrderOpen: false,
      IsExchangeRateRMB: true,
      fromBtns: [],
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      printInfo: {
        isGetDefaultParam: false, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "STK_In", // 打印模板类型
      },
      isDisabled: false,
      GCode: "StkIn",
      // end 打印模块参数

      isSwitchToSCM: false, // 保存成功后提交到供应链报关单
      invTypeList: [],
      qtyUnitList: [],
      qualityList: [],
      bankAccountNameList: [],
      dialogbox: {
        height: "640px",
        width: "1000px",
      },
      systemConfig: {
        hasFinance: false,
      },
      hasLoanService: false, // 是否有月结资质
      useLoanService: false, // 是否使用正能量月结
      saveButtonText: "保存",
      monthlyServiceIntroVisible: false,
      //公司配置
      CompanySetup: {
        EnableNotUpperCase: false, //单据的型号、品牌﹑封装、年份默认为非大写格式
      },
    };
  },
  props: {
    editType: String,
    title: {
      type: String,
      default: () => {
        return "编辑入库单";
      },
    },
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    stkInGUID: String,
    stockLine: Array, // 库存对象
    stkInGUIDLine: Array, // 入库明细对象
    showInAddBystock: Boolean, // 当从库存列表中传过来的值时刷新
    showInAddBystkin: Boolean, // 当从入库列表中传过来的值时刷新
    showInAddBystkoutLine: Boolean, // 当从入库明细中传过来的值时刷新
    // POModel: Object,
    isRefresh: Boolean, // 订货入库
    stkPurchaseGuid: String, // 订单GUID

    isRefreshVMI: Boolean, // 供应商库存
    VMGUIDS: Array, // 供应商库存
    autoAddNewRow: {
      // 是否在明细自动增加一行
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  watch: {
    dialogLoading(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.flexGrid.doLayout();
        });
      }
    },
    showInAddBystkin: {
      // 刷新页面(从入库列表中传过来的数据)
      handler(value) {
        if (!_.isEmpty(this.stkInGUID)) {
          this.isPopup = true;
          this.LoadEditData(this.stkInGUID);
        }
      },
      immediate: true,
    },
    showInAddBystkoutLine: {
      handler(value) {
        if (!_.isEmpty(this.stkInGUIDLine)) {
          this.isPopup = true;
          this.LoadLineAddData(this.stkInGUIDLine, false);
        }
      },
      immediate: true,
    },
    isRefresh: {
      handler(value) {
        if (!_.isEmpty(this.stkPurchaseGuid)) {
          this.isPopup = true;
          this.LoadStkinByPurchaseGuid(this.stkPurchaseGuid);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.systemConfig.hasFinance =
    this.getCompanySetupValue("HasFinance") === "1";
    this.$nextTick(() => {
      if (
        (this.editType === "add" || this.editType === "addNew") &&
        this.autoAddNewRow
      ) {
        this.$refs.flexGrid.addRow(true);
      }
    });
    this.$post("SysAccount/GetWarehouses", {}).then(e => this.warehouses = e)
  },
  async created() {
    let innerHeight = window.innerHeight;
    let lastHeight = innerHeight - 40;
    if (innerHeight > 800) {
      lastHeight = 800;
    } else if (innerHeight > 768 && innerHeight <= 800) {
      lastHeight = innerHeight;
    }
    if (lastHeight % 2 !== 0) {
      lastHeight = lastHeight - 1;
    }
    this.dialogbox.height = lastHeight + "px";

    this.bankAccountNameList = _.map(
      await this.getBankAccountListAsync,
      (opt) => {
        return {
          key: opt.key,
          value: opt.value + " (" + opt.data + ")",
        };
      }
    );
  },
  methods: {
    invPositionRender(h, { row, column, columnIndex }) {
      const options = this.warehouses.map(e => h('el-option', { props: { value: e.WarehouseName, label: e.WarehouseName } }))
      const el = h("el-select", {
        attrs: {
          size: "mini",
          filterable: true,
          clearable: true,
          "allow-create": true,
          "default-first-option": true,
          value: row[column.binding],
          class: 'el-select-transparent',
          placeholder: ""
        }, on: {
          change: (v) => {
            this.$set(row, column.binding, v);
          },
          blur:(v)=>{
            var el=v.target
            if(el && el.value) this.$set(row, column.binding, el.value);
          }
        }
      }, options);
      return el;
    },
    onInit: async function () {
      let config =
        (await this.isSaveStorage("stkInline", CONFIG.configURL)) || {};
      let configs =
        (await this.isSaveStorage("stkIn", CONFIG.configJsonURL)) || {};

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      // 获取参数配置
      this.ParamJosn = configs.ParamJosn;
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn;
      this.paramConfiguration();
      this.resourceList = (config.ResourceList || []).map((item) => item.Code);
      this.mainResourceList = (configs.ResourceList || []).map(
        (item) => item.Code
      );
      // 隐藏状态列和退货量列
      _.each(columns, (item, index) => {
        if (item.binding === "StatusName") {
          item.hasRole = false;
          item.visibled = false;
        }
        if (item.binding === "ReturnQty") {
          item.hasRole = false;
          item.visibled = false;
        }
        if (item.binding == "InvPosition" && !item.isReadOnly) {
          item.renderCell = this.invPositionRender
        }
      });
      if (!this.$store.state.accountInfo.IsMainAccount) {
        _.each(columns, (item, index) => {
          // 看备注1 没有这个权限隐藏列
          if (item.binding === "Remark") {
            item.hasRole = this.hasRes("ViewRemark1");
          }
          // 看备注2 没有这个权限隐藏列
          if (item.binding === "Remark1") {
            item.hasRole = this.hasRes("ViewRemark2");
          }
          // 看备注3  没有这个权限隐藏列
          if (item.binding === "Remark2") {
            item.hasRole = this.hasRes("ViewRemark3");
          }
          // 不看替代型号，有这个权限隐藏列
          if (item.binding === "ReplaceModel") {
            item.hasRole = !this.hasRes("NoSeeReplaceModel");
          }
        });
      }
      let invTypeList = await this.selectStorageType;
      // 库存类型
      let stkTypeColumn = _.find(
        columns,
        (column) => column.name === "InvType"
      );
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: invTypeList,
          showDropDown: true,
        });
      let qtyUnitList = await this.selectOptionsQtyUnit;
      // 单位
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Unit";
        }),
        { dataMap: qtyUnitList, showDropDown: true }
      );
      let qualityList = await this.selectOptionsQuality;
      // 品质
      _.extend(
        _.find(columns, function (column) {
          return column.binding === "Quality";
        }),
        { dataMap: qualityList, showDropDown: true }
      );
      if (this.hasSpecialRes("NoSeeProfit") === true) {
        let ProfitMarginsColumn = _.find(
          columns,
          (column) => column.name === "ProfitMargins"
        ); // 利润率
        ProfitMarginsColumn &&
          _.extend(ProfitMarginsColumn, { isReadOnly: true, visible: false });
      }

      this.defaultConfig = config;
      columns = this.renderRequiredColumn(columns);

      // 默认税点
      let value = this.getCompanySetupValue("DefaultTaxRate");
      if (value) {
        this.StkIn.TaxRate = value;
      }

      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.fromPurchaseOrderOpen = false;
      // 从各个地方加载数据
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkInGUID
      ) {
        let stkinguid = this.$store.state.paramOrder.stkInGUID;
        let order = {};
        this.$store.commit("setParamOrder", order);
        await this.LoadEditData(stkinguid);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkInGUIDLine
      ) {
        let stkinguidLine = this.$store.state.paramOrder.stkInGUIDLine;
        let order = {};
        let isreturn = false;
        if (this.$store.state.paramOrder.IsReturn) {
          isreturn = this.$store.state.paramOrder.IsReturn;
        }
        this.$store.commit("setParamOrder", order);
        this.LoadLineAddData(stkinguidLine, isreturn);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stockLine
      ) {
        let stkoutguidline = this.$store.state.paramOrder.stockLine;
        this.$store.commit("setParamOrder", {});
        this.LoadListByStock(stkoutguidline, false);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.POModels
      ) {
        let info = this.$store.state.paramOrder.POModels;
        this.$store.commit("setParamOrder", {});
        // this.POModel = info
        this.isPopup = false;
        this.showAddRowButton = false;
        this.showAddRowRightMenu = false;
        this.isAllow = false; // 编辑禁用选择
        this.fromPurchaseOrderOpen = true;
        this.LoadPurchaseData(info);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.StkPurchaseGuid
      ) {
        let guid = this.$store.state.paramOrder.StkPurchaseGuid;
        this.$store.commit("setParamOrder", {});
        this.LoadStkinByPurchaseGuid(guid);
      }
      // 批量采购入库
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.StkPurchaseGuidPL
      ) {
        let guid = this.$store.state.paramOrder.StkPurchaseGuidPL;
        this.$store.commit("setParamOrder", {});
        this.LoadStkinByPurchaseGuidPL(guid);
      }
      // 询价记录入库
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.RFQ_InquireModel
      ) {
        let info = this.$store.state.paramOrder.RFQ_InquireModel;
        this.$store.commit("setParamOrder", {});
        this.InquireModel(info);
      }
      // 采购报价单生成入库单(按整张需求单)
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.BOMGUIDS
      ) {
        let info = this.$store.state.paramOrder.BOMGUIDS;
        this.$store.commit("setParamOrder", {});
        this.LoadListByBOMGUIDS(info);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.InquireModel
      ) {
        let info = this.$store.state.paramOrder.InquireModel;
        this.$store.commit("setParamOrder", {});
        this.InquireModel(info);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.StkInRecord
      ) {
        let info = this.$store.state.paramOrder.StkInRecord;
        this.$store.commit("setParamOrder", {});
        this.LoadListByStock(info);
      }
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.stkOutLines
      ) {
        let stkOutLines = this.$store.state.paramOrder.stkOutLines;
        this.$store.commit("setParamOrder", {});
        this.$nextTick(function () {
          _.each(stkOutLines, (items) => {
            items.SalesPrice = items.PriceInTax;
            items.PriceNoTax = items.BuyPrice;
            items.Price = items.BuyPrice;
            let amount = this.toFloat(
              this.ConvertNumber(items.BuyPrice) *
                this.ConvertNumber(items.Qty),
              2
            );
            items.Amount = amount;
            items.Status = 1;
          });
          this.StkIn.SupplierName = stkOutLines[0].SupplierInfo;
          this.StkIn.LineList = stkOutLines;
          this.itemSource = stkOutLines;
          this.CalcPay(true);
          this.onBindData();
        });
      }
      // 按需求单生成 入库单
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.bomInfo
      ) {
        let info = this.$store.state.paramOrder.bomInfo;
        await this.LoadBomLine(info, false);
        this.$store.commit("setParamOrder", {});
      }

      // 从淘宝订单入库
      if (
        this.$store.state.paramOrder &&
        this.$store.state.paramOrder.taobaoOrder
      ) {
        let orders = this.$store.state.paramOrder.taobaoOrder;
        this.$store.commit("setParamOrder", {});
        this.loadByTaobao(orders);
      }

      this.fromBtns = [
        {
          name: "新增一行",
          iconName: "icon-add_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.$refs.flexGrid.addRow(true);
          },
        },
        {
          name: "删除选择行",
          isShow: !this.isDisabled,
          iconName: "icon-delete_btn_ic",
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
            let Amount = 0;
            _.each(this.itemSource, (item, i) => {
              if (item.Amount) Amount += Number(item.Amount);
            });
            this.StkIn.PayablesAmount = Amount;
          },
        },
        {
          name: "导入数据",
          iconName: "icon-add_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.setExcelColumns = _.filter(this.columns, (item) => {
              return item.isReadOnly === false;
            });
            this.isBulkAddShow = !this.isBulkAddShow;
          },
        },
        {
          name: "我的库存",
          iconName: "icon-save_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.StkStockCheckVisible = true;
          },
        },
      ];
      this.refreshColumnsPermission();
      this.$nextTick(() => {
        this.dialogLoading = false;
      });
      this.hasLoanService = await this.hasLoanServiceAsync;
      await this.initCompanySetup();

    },
    //初始化公司配置
    async initCompanySetup() {
      this.$post(
        CONFIG.GetCompanySetupUrl,
        { SCode: "EnableNotUpperCase" },
        (data, logic) => {
          if (logic.code === 200) {
            data = data.toString().toUpperCase();
            this.CompanySetup.EnableNotUpperCase =
              data === "1" || data === "Y" || data === "YES" || data === "TRUE";
          }
        }
      );
    },
    //刷新列权限
    refreshColumnsPermission() {
      let notModifyStkInQty = this.hasResMain("Not_Allow_Modify_StkIn_Qty"); // 编辑不修改入库数量
      // 循环列
      _.each(this.columns, (item, index) => {
        switch (item.binding) {
          case "Qty":
            if (notModifyStkInQty && !this.StkIn.IsAdd) {
              item.isReadOnly = notModifyStkInQty;
            }
            break;
        }
      });
    },
    paramConfiguration() {
      // 单据保存后立即打印 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinuousRecord = true;
      } else {
        this.IsContinuousRecord = false;
      }

      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "1") {
        this.IsPrint = true;
      } else {
        this.IsPrint = false;
      }
      // 默认单位
      let paramQtyUnit = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyUnit") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyUnit && paramQtyUnit.ParamValue) {
        this.paramQtyUnit = paramQtyUnit.ParamValue;
      }

      let defaultUnit = this.getCompanySetupValue("DefaultUnit");
      if (defaultUnit) {
        this.paramQtyUnit = defaultUnit;
      }
      // 默认库存类型
      let paramQtyType = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn.ParamCode === "QtyType") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyType && paramQtyType.ParamValue) {
        this.paramQtyType = paramQtyType.ParamValue;
      }

      // 默认币种
      let defaultCurrencyCode = this.getCompanySetupValue(
        "DefaultPurchaseCurrencyCode"
      );
      if (defaultCurrencyCode) {
        this.StkIn.CurrencyCode = defaultCurrencyCode;
        let exchangeObj = _.find(
          this.selectOptionsCurrencyCode,
          (m) => m.key === defaultCurrencyCode
        );
        if (exchangeObj && exchangeObj.ExchangeRate) {
          this.StkIn.ExchangeRate = exchangeObj.ExchangeRate;
        }
      }
      // 默认税率
      let defaultTaxRate = this.getCompanySetupValue("DefaultTaxRate");
      if (defaultTaxRate) {
        this.StkIn.TaxRate = defaultTaxRate;
      }

      // 是否带出上次价格
      let paramIsTakeOutPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "IsTakeOutPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramIsTakeOutPrint) &&
        paramIsTakeOutPrint.ParamValue === "0"
      ) {
        this.IsTakeOutPrint = false;
      } else {
        this.IsTakeOutPrint = true;
      }

      // 供应商名称是否必填
      let value = this.getCompanySetupValue("MustCompanyName");
      if (value === "1") {
        this.IsSupplierNameRequired = true;
        this.SupplierNameTitle = "*供应商：";
      }
      // 验证是否先做采购单
      let ExistPurchaseOrder = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === "ExistPurchaseOrder") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(ExistPurchaseOrder) &&
        ExistPurchaseOrder.ParamValue === "0"
      ) {
        this.StkIn.ExistPurchaseOrder = false;
      } else {
        this.StkIn.ExistPurchaseOrder = true;
      }

      // 打印单据模板
      // 设置打印
      this.printInfo.template = this.getPrintTemplate(this.ParamJosn);
      this.printInfo.title = this.getPrintTitle(this.ParamJosn);
    },
    onBindData(pageIndex = 1) {
      this.ParamJonsValue();
      this.StkIn.LineList = this.itemSource;
      // 如果单据是已审核状态，则部分列不允许编辑
      if (this.StkIn && this.StkIn.Status === 3) {
        let readonlyCols = "SalesModel,Model,Qty".split(",");
        _.each(this.columns, (col) => {
          if (_.some(readonlyCols, (code) => code === col.binding)) {
            col.isReadOnly = true;
            col.className = "znl-is-read-only";
          }
        });
      }
      this.$emit("page-loading", false);
    },
    // 默认参数设置赋值
    ParamJonsValue() {
      _.each(this.itemSource, (item, i) => {
        if (this.hasValue(this.itemSource[i].StkInLineGUID)) {
          !this.hasValue(this.itemSource[i].Unit)
            ? (this.itemSource[i].Unit = this.paramQtyUnit)
            : "";
          !this.hasValue(this.itemSource[i].InvType)
            ? (this.itemSource[i].InvType = this.paramQtyType)
            : "";
        }
      });
    },
    // 供应商名称联想
    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post(
          "CompanyContacts/SearchCompanyContacts",
          {
            FieldWhereString: JSON.stringify({
              CompanyName: queryString,
            }),
            PageIndex: 1,
            PageSize: 20,
            CompanyTypes: "2,3",
          },
          (data) => {
            this.restaurants = data.ResultList;
            let restaurants = this.restaurants;
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            // 调用 callback 返回建议列表的数据
            _.each(results, (item) => {
              item.value = item.CompanyName;
            });
            cb(results);
            _.delay(() => {
              this.$refs.znlAutocomplete.$el
                .querySelector(".el-input__inner")
                .focus();
            }, 100);
          }
        );
      }
      this.startTime = new Date();
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    setGridData(list) {
      this.addRowToItemSource(list);
    },
    suggestionsSelect(item) {
      this.companyChecked([item]);
    },
    setDefaultData(targetObj, defObj) {
      for (let key in defObj) {
        let tarVal = targetObj[key];
        if (tarVal === undefined || tarVal === null || tarVal === "") {
          targetObj[key] = defObj[key];
        }
      }
    },
    CurrencyCodeChange(val) {
      // 币种改变选项事件
      let itemVal = val ? val.key : this.StkIn.CurrencyCode; // 选中的值
      if (!this.hasValue(itemVal)) {
        itemVal = "RMB";
      }
      if (itemVal === "RMB") {
        this.IsExchangeRateRMB = true;
      } else {
        this.IsExchangeRateRMB = false;
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) {
        return item.key === itemVal;
      });
      this.StkIn.ExchangeRate = info.ExchangeRate;
    },
    async save(isStorge) {
      // 保存并入库
      const vm = this;
      if (this.StkIn.LineList.length > 0) {
        this.isDisabled = true;
        this.StkIn.IsAllowStroge = isStorge;
        vm.loading = true;
        return this.$post(
          CONFIG.StkInSaveAndStorageURL,
          this.StkIn,
          (data, logic) => {
            vm.isDisabled = false;
            vm.loading = false;
            if (logic.code !== 200) {
              this.$message({ message: logic.msg, type: "error" });
            } else {
              this.$message({ message: "保存成功", type: "success" });
              this.$emit("save-success");
              this.StkIn = {};
              this.StkIn = data;
              this.itemSource = data.LineList;
              this.StkIn.IsAdd = false;
              if (this.IsPrint) {
                this.onPrint();
              }
              if (this.isSwitchToSCM) {
                this.onSwitchToSCM(data.StkInGUID);
              }

              if (isStorge && !this.IsContinuousRecord) {
                this.$emit("close");
              } else if (this.IsContinuousRecord) {
                vm.onResetStkin(); // 重置
              }

              if (this.useLoanService) {
                openLoanService(data.BillNo);
              }
            }
          }
        ).finally(() => {
          vm.loading = false;
          this.isDisabled = false;
        });
      }
    },

    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    onPrint() {
      var tableName = this.printInfo.template; // 模板ID
      var InGuid = this.StkIn.StkInGUID;
      if (!this.hasValue(tableName)) {
        this.$message({ message: "请先选择打印模板", type: "warning" });
        this.znlPrintVisible = true;
      } else if (InGuid === "" || InGuid === null || InGuid === undefined) {
        this.$message({ message: "请先保存后才能打印", type: "error" });
      } else {
        let printParm = {
          TableName: tableName,
          Guid: InGuid,
          SubCompanyId: this.printInfo.title,
        };
        this.printStkIn(printParm, () => {});
      }
    },

    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    hasResMain(code) {
      return _.includes(this.mainResourceList, code);
    },
    stkinSave() {
      if (this.isDisabled) {
        return;
      }
      try {
        this.isDisabled = true;
        // 保存
        if (this.checkData() && this.checkLength()) {
          this.save(false);
        }
      } finally {
        this.isDisabled = false;
      }
    },
    stkinSaveAndStorage() {
      if (this.isDisabled) {
        return;
      }
      try {
        this.isDisabled = true;
        // 保存并入库
        if (this.checkData() && this.checkLength()) {
          this.save(true);
        }
      } finally {
        this.isDisabled = false;
      }
    },

    // 保存并提交到委托报关单
    async onSwitchToSCM() {
      this.isSwitchToSCM = false;
      stockInToSCM();
    },

    onDeletedRows() {
      _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
        _.each(this.itemSource, (item, i) => {
          if (
            _.isEqual(
              _.pick(item, (value) => {
                return !_.isUndefined(value);
              }),
              _.pick(data, (value) => {
                return !_.isUndefined(value);
              })
            )
          ) {
            this.itemSource.splice(i, 1);
            return;
          }
        });
      });
      this.StkIn.LineList = this.itemSource;
      this.CalcPay(false);
    },
    checkData() {
      let ret = true;
      this.onbuyerChange();
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        ret = false;
        return this.$message({
          message: "请修改填写错误的单元格内容后再保存",
          type: "error",
        });
      } else if (!this.hasValue(this.StkIn.SupplierName)) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({ message: "供应商名不能为空", type: "warning" });
      } else if (!this.hasValue(this.StkIn.ReceiveTime)) {
        ret = false;
        this.$refs.ReceiveTime.handleFocus();
        this.$message({ message: "请选择入库日期", type: "warning" });
      } else if (
        this.StkIn.ReceiveUserID <= 0 ||
        this.StkIn.ReceiveUserID === undefined ||
        this.StkIn.ReceiveUserID === "" ||
        this.StkIn.ReceiveUserID === null
      ) {
        ret = false;
        this.$refs.ReceiveUserID.handleFocus();
        this.$message({ message: "请选择入库员", type: "warning" });
      } else if (
        this.StkIn.CurrencyCode === undefined ||
        this.StkIn.CurrencyCode === "" ||
        this.StkIn.CurrencyCode === 0
      ) {
        ret = false;
        this.$refs.CurrencyCode.handleFocus();
        this.$message({ message: "请选择币种", type: "warning" });
      } else if (this.useLoanService && this.StkIn.CurrencyCode !== "RMB") {
        this.$message({
          message: "币种为人民币的单据才能申请正能量月结服务",
          type: "warning",
        });
        this.$refs.CurrencyCode.handleFocus();
        ret = false;
      } else if (this.useLoanService && this.StkIn.TaxRate !== 13) {
        this.$refs.StkInTaxRate.handleFocus();
        this.$message({
          message: "税率为13的单据才能申请正能量月结服务",
          type: "warning",
        });
        ret = false;
      } else if (
        !this.isReadonly &&
        (!this.hasValue(this.StkIn.PaidAmount) ||
          this.StkIn.PaidAmount === 0) &&
        this.StkIn.PaymentType === "现金"
      ) {
        ret = false;
        this.$message({
          message: "现金付款方式，必须输入实付金额",
          type: "warning",
        });
      } else if (
        this.systemConfig.hasFinance &&
        !this.isReadonly &&
        !this.hasValue(this.StkIn.CBGUID) &&
        this.hasValue(this.StkIn.PaidAmount) &&
        this.StkIn.PaidAmount !== 0
      ) {
        ret = false;
        this.$message({ message: "请选择付款账号", type: "warning" });
      } else if (
        !this.isReadonly &&
        this.hasValue(this.StkIn.PaymentType) &&
        this.StkIn.PaymentType.indexOf("月结") !== -1 &&
        !this.hasValue(this.StkIn.ExpectPayDate)
      ) {
        ret = false;
        this.$message({
          message: "付款方式:【月结】请输入预付款日期",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkIn.DiscountAmount) &&
        !isFinite(this.StkIn.DiscountAmount)
      ) {
        ret = false;
        this.$refs.DiscountAmount.handleFocus();
        this.$message({
          message: "折扣金额必须为数字或者小数",
          type: "warning",
        });
        return ret;
      } else {
        //删除为空的最后一行
        if (this.itemSource.length > 1) {
          var lastCol = this.itemSource.length - 1;
          var lastRow = this.itemSource[lastCol];
          if (
            !this.hasValue(lastRow["Model"]) &&
            !this.hasValue(lastRow["Qty"]) &&
            !this.hasValue(lastRow["Price"])
          )
            this.itemSource = this.itemSource.splice(0, lastCol);
        }
        let rows = this.itemSource;
        if (rows.length > 0) {
          let rowId = 0;
          let messageStr = "";
          _.some(rows, (item) => {
            rowId = rowId + 1;
            if (item["Model"] === null || item["Model"] === undefined) {
              ret = false;
              this.$message({
                message: "单据详情第" + rowId + "行 型号不能为空",
                type: "warning",
              });
            } else if (
              this.hasValue(item["Model"]) &&
              item["Model"].length > 200
            ) {
              ret = false;
              this.$message({
                message: "单据详情第" + rowId + "行 型号长度不能超过200个字符",
                type: "warning",
              });
            } else if (!isFinite(item["Qty"]) || !this.hasValue(item["Qty"])) {
              ret = false;
              this.$message({
                message: "单据详情第" + rowId + "行 数量不能为空",
                type: "warning",
              });
            } else if (
              !isFinite(item["Price"]) ||
              !isFinite(item["PriceNoTax"]) ||
              !this.hasValue(item["Price"])
            ) {
              ret = false;
              this.$message({
                message:
                  "单据详情第" + rowId + "行 未税价必须是数值型且不能为空",
                type: "warning",
              });
            } else if (
              this.hasValue(item["ProductCategory"]) &&
              item["ProductCategory"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 产品类型不能超过50个字符";
            } else if (
              this.hasValue(item["Brand"]) &&
              item["Brand"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 品牌长度不能超过50个字符";
            } else if (
              this.hasValue(item["Packaging"]) &&
              item["Packaging"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 封装长度不能超过50个字符";
            } else if (
              this.hasValue(item["MakeYear"]) &&
              item["MakeYear"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 年份长度不能超过50个字符";
            } else if (this.hasValue(item["MPQ"]) && item["MPQ"].length > 50) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 包装不能超过50个字符";
            } else if (
              this.hasValue(item["StorageName"]) &&
              item["StorageName"].length > 50
            ) {
              ret = false;
              messageStr = "单据明细第" + rowId + "行 位置长度不能超过50个字符";
            } else if (
              this.hasValue(item["Remark"]) &&
              item["Remark"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 备注长度不能超过200个字符";
            } else if (
              this.hasValue(item["Remark1"]) &&
              item["Remark1"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 备注2长度不能超过200个字符";
            } else if (
              this.hasValue(item["Remark2"]) &&
              item["Remark2"].length > 200
            ) {
              ret = false;
              messageStr =
                "单据明细第" + rowId + "行 备注3长度不能超过200个字符";
            }
          });
          if (messageStr.length > 0) {
            ret = false;
            this.$message({ message: messageStr, type: "warning" });
          }
          this.StkIn.LineList = this.itemSource;
        } else {
          ret = false;
          this.loading = false;
          return this.$message({
            message: "请添加入库单详情",
            type: "warning",
          });
        }
      }
      return ret;
    },
    checkLength() {
      let ret = true;
      if (
        this.hasValue(this.StkIn.SupplierName) &&
        this.StkIn.SupplierName.length > 100
      ) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({
          message: "供应商名称长度不能超过100个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkIn.Contact) &&
        this.StkIn.Contact.length > 50
      ) {
        ret = false;
        this.$refs.Contact.handleFocus();
        this.$message({
          message: "联系人长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkIn.Telephone) &&
        this.StkIn.Telephone.length > 50
      ) {
        ret = false;
        this.$refs.Telephonenumb.handleFocus();
        this.$message({
          message: "联系电话长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkIn.Address) &&
        this.StkIn.Address.length > 200
      ) {
        ret = false;
        this.$refs.Address.handleFocus();
        this.$message({
          message: "地址长度不能超过200个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkIn.Remark) &&
        this.StkIn.Remark.length > 1000
      ) {
        ret = false;
        this.$message({
          message: "入库备注不能超过1000个字符",
          type: "warning",
        });
      }
      return ret;
    },
    onBeforeAddRow: async function () {},
    setClientSelectLoading(val) {
      this.dialogLoading = val;
    },
    addExcelData(data) {
      let index = 0;
      if (this.itemSource && this.itemSource.length > 0) {
        index = this.itemSource.length - 1;
        let info = this.itemSource[index];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
          index--;
        }
      }
      _.each(data, (item) => {
        this.getValue(item);
      });

      _.each(data, (item) => {
        index++;
        item.ID = index;
        this.itemSource.push(item);
      });
      this.isBulkAddShow = false;
    },
    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit;
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType;
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.PriceNoTax)) {
        // 计算含税
        let _price = this.CalcPriceInTax(item.PriceNoTax);
        item.Price = _price === 0 ? null : _price;
      } else if (this.hasValue(item.Price)) {
        // 含税算未税
        item.PriceNoTax = this.CalcPrice(item.Price);
      }
      item.Amount = this.CalcAmout(item.Qty, item.Price).toFixed(2);
      this.CalcPay(false);
    },

    LoadLineAddData: async function (guid, isreturn) {
      // 根据出库单明细GUID加载实体
      let stkIn = await this.$post(CONFIG.StkInSearchByLineguid, {
        InLineGuids: guid,
        IsReturn: isreturn,
      });
      this.StkIn = _.extend({}, this.StkIn, stkIn);
      this.getAddress(this.StkIn);
      this.$nextTick(function () {
        this.itemSource = stkIn.LineList;
        this.onBindData();
        this.CalcPay(true);
        this.StkIn.IsAdd = this.editType === "add";
        if (this.StkIn.IsAdd) {
          this.StkIn.StkInGUID = "";
        }
        // this.isAllow = false// 编辑禁用选择
        this.StkIn.isAllowStorge = this.StkIn.Status !== 3; // 已入库的禁用
        this.ResTaxRate();
      });
    },

    // 根据入库单GUID加载实体
    LoadEditData: async function (guid) {
      let stkIns = await this.$post(CONFIG.StkInSearchById, {
        StkInGUID: guid,
      });
      if (stkIns.IsAudited || stkIns.Status === 2 || stkIns.Status === 3) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.StkIn = stkIns;
      this.StkIn.IsAdd = false;
      this.getAddress(this.StkIn);
      this.isReadonly = true;
      this.itemSource = stkIns.LineList;
      this.$nextTick(function () {
        this.onBindData();
        this.StkIn.IsAdd = false;
        this.StkIn.isAllowStorge = this.StkIn.Status !== 3; // 已入库的禁用

        this.StkIn.stkInGUID = guid;
      });
    },
    // 验证 GUID加载实体税点是否为空 如果为空则默认参数配置， 从新计算
    ResTaxRate() {
      if (!this.hasValue(this.StkIn.TaxRate)) {
        this.StkIn.TaxRate = this.paramTax;
        this.CalcTaxRate();
      }
    },

    // 根据采购单GUID加载实体
    LoadPurchaseData: async function (info) {
      return this.$post(CONFIG.CreateByPOGUID, info, (item, logic) => {
        if (logic.code === 200) {
          item.BillNo = null;
          let newlist = [];
          let rowID = 1;
          _.each(item.LineList, (data) => {
            let newItem = data;
            newItem.BeforeFirstQtyValue = data.Qty;
            newItem.RowGuid = rowID++;
            newlist.push(newItem);
          });
          item.LineList = newlist;
          if (!this.hasValue(item.ReceiveUserID)) {
            item.ReceiveUserID = this.$store.state.accountInfo.UserID;
          }
          if (!this.hasValue(item.Receiver)) {
            item.Receiver = this.$store.state.accountInfo.UserName;
          }
          this.StkIn = _.extend({}, this.StkIn, item);
          this.getAddress(this.StkIn);
          this.$nextTick(function () {
            this.itemSource = this.StkIn.LineList;
            this.onBindData();
            this.CalcPay(true);
            this.StkIn.IsAdd = true;
          });
        } else {
          return this.$message({ message: logic.msg, type: "error" });
        }
      });
    },
    // 根据订货GUID加载实体
    LoadStkinByPurchaseGuid: async function (guid) {
      let stkIn = await this.$post(CONFIG.SearchByPurchaseGUID, {
        PurchaseGuid: guid,
      });
      this.StkIn = _.extend({}, this.StkIn, stkIn);
      this.getAddress(this.StkIn);
      this.itemSource = stkIn.LineList;
      this.$nextTick(function () {
        this.onBindData();
        this.CalcPay(true);
        this.StkIn.IsAdd = true;
        this.isAllow = true; // 编辑禁用选择
        this.ResTaxRate();
      });
    },
    // 批量采购入库
    LoadStkinByPurchaseGuidPL: async function (guid) {
      let stkIn = await this.$post(CONFIG.SearchByPurchaseGUIDPL, {
        PurchaseGuids: guid,
      });
      this.StkIn = _.extend({}, this.StkIn, stkIn);
      this.getAddress(this.StkIn);
      this.$nextTick(function () {
        this.onBindData();
        this.CalcPay(true);
        this.StkIn.IsAdd = true;
        this.isAllow = true; // 编辑禁用选择
        this.ResTaxRate();
      });
    },

    // 加载bom配单数据
    LoadBomLine: async function (bomInfo) {
      let orders = await this.$post(CONFIG.bomLineGotoStkInURL, bomInfo);
      this.BindDataToLineList(orders);
    },

    BindDataToLineList(orders) {
      _.each(orders.DetailList, (item) => {
        let BuyPrice = item.BuyPrice === undefined ? null : item.BuyPrice; // 供应商报价
        let NTQuotePrice =
          item.NTQuotePrice === undefined ? null : item.NTQuotePrice; // 销售定价
        item.TaxRate = null;
        item.PriceNoTax = BuyPrice;
        item.Price = BuyPrice;
        item.Amount = this.toFloat(BuyPrice * item.Qty, 6);
        item.SalesPrice = NTQuotePrice;
      });
      this.StkIn = _.extend({}, this.StkIn, orders);
      this.StkIn.LineList = orders.DetailList;
      this.$nextTick(function () {
        this.itemSource = this.StkIn.LineList;
        this.CalcPay(true);
        this.StkIn.IsAdd = true;
        this.isAllow = true; // 新增

        this.ResTaxRate();
      });
    },

    LoadListByBOMGUIDS: async function (mltGuids) {
      // 根据传入的客户需求GUIDS加载明细列表
      let orders = await this.$post(CONFIG.SearchByBOMGUIDS, {
        BOMGUID: mltGuids,
      });
      _.each(orders.LineList, (item) => {
        item.PriceNoTax = item.BuyPrice;
        item.Price = item.BuyPrice;
        item.Amount = this.toFloat(item.BuyPrice * item.Qty, 6);
        item.SalesPrice = item.PriceInTax;
      });
      this.StkIn = _.extend({}, this.StkIn, orders);
      this.getAddress(this.StkIn);
      this.$nextTick(function () {
        this.itemSource = this.StkIn.LineList;
        this.onBindData();
        this.CalcPay(true);
        this.isAllow = true; // 新增
        this.StkIn.TaxRate = this.paramTax;
        this.ResTaxRate();
      });
    },

    // 报价记录入库不带价格
    clearPrice() {
      let tempSource = this.StkIn.LineList;

      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          tempSource[i].PriceNoTax = null;
          tempSource[i].Amount = null;
          tempSource[i].Price = null;
        }
        this.itemSource = tempSource;
        _.each(tempSource, (item, i) => {
          this.setCellData(
            i,
            this.getColumn("PriceNoTax").index,
            item.PriceNoTax || null
          );
          this.setCellData(
            i,
            this.getColumn("Price").index,
            item.Price || null
          );
          this.setCellData(
            i,
            this.getColumn("Amount").index,
            item.Amount || null
          );
        });
        // this.CalcPay(false)
      }
    },
    // 加载供应商库存
    LoadStkVMI: async function (rows) {
      // console.log('rows', rows)
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stocks) => {
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: invQty,
            PriceNoTax: this.ConvertNumber(stocks.UnitPrice),
            Price: this.CalcPriceInTax(this.ConvertNumber(stocks.UnitPrice)),
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.CalcPriceInTax(this.ConvertNumber(stocks.UnitPrice))
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Partner: stocks.Partner,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            StorageName: stocks.InvPosition,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载供应商库存失败，请刷新后重试",
          type: "error",
        });
      }
    },
    isNullItemSource() {
      return (
        this.itemSource.length === 0 ||
        (this.itemSource.length < 2 &&
          (this.itemSource[0].Model === "" ||
            this.itemSource[0].Model === undefined) &&
          this.itemSource[0].Qty === null)
      );
    },
    CalcStkOutAmoutAndGrossProfit(isEdit) {
      // 计算金额和毛利润, 分摊费
      this.StkIn.Amount = 0.0; // 应收
      this.StkIn.GrossProfit = 0.0; // 利润总额
      _.each(isEdit ? this.StkIn.LineList : this.itemSource, (o, index) => {
        this.StkIn.Amount += this.toFloat(o.Amount, 2);
        this.StkIn.GrossProfit += this.toFloat(o.GrossProfit, 2);
      });
      let GrossProfitSummary =
        this.StkIn.GrossProfit -
        this.ConvertNumber(this.StkIn.DiscountAmount) -
        this.ConvertNumber(this.StkIn.OtherFeeAmount);
      this.StkIn.GrossProfit = this.toFloat(GrossProfitSummary, 2);
      // this.StkIn.Amount = this.toFloat(this.StkIn.Amount, 2)
      this.CalcProfitMargins();
    },
    // 加载历史发货明细
    loadStkOutLine(rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型

            Price: this.ConvertNumber(stocks.Price),
            PriceInTax: this.CalcPriceInTax(this.ConvertNumber(stocks.Price)), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.CalcPriceInTax(this.ConvertNumber(stocks.Price))
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载发货明细失败，请刷新后重试",
          type: "error",
        });
      }
    },
    // 加载报价明细
    loadQuotedPriceLine(rows) {
      let rowID = 0;
      if (rows.length > 0) {
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            Price: this.ConvertNumber(stocks.QuotePrice),
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.QuotePrice)
            ), // 含税价
            Amount: this.CalcPriceInTax(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.QuotePrice)
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载报价明细失败，请刷新后重试",
          type: "error",
        });
      }
    },

    CalcPriceInTax(price) {
      // 计算含税价
      let nums = price * (1 + this.StkIn.TaxRate / 100);
      return this.toFloat(nums, 6);
    },
    CalcPrice(priceintax) {
      // 计算未税
      let nums = priceintax / (1 + this.StkIn.TaxRate / 100);
      return this.toFloat(nums, 6);
    },
    CalcAmout(qty, price) {
      // 计算金额
      let nums = qty * price;
      return this.toFloat(nums, 2);
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.StkIn.PayablesAmount = null;
      _.each(isEdit ? this.StkIn.LineList : this.itemSource, (o, index) => {
        this.StkIn.PayablesAmount += this.toFloat(o.Amount, 2);
      });
      this.StkIn.PayablesAmount = this.toFloat(this.StkIn.PayablesAmount, 2);
      if (this.StkIn.PaymentType === "现金") {
        this.StkIn.PaidAmount =
          this.toFloat(this.StkIn.PayablesAmount, 2) -
          this.ConvertNumber(this.StkIn.DiscountAmount);
      }
    },
    ConvertNumber(num) {
      // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null
        ? 0
        : this.toFloat(num);
    },
    onResetStkin() {
      // 重置入库实体
      let info = {
        // 入库单对象
        BillNo: "", // 采购单号
        ReceiveTime: new Date().toLocaleDateString(), // 入库日期
        ReceiveUserID: this.$store.state.accountInfo.UserID, // 采购员ID
        Receiver: this.$store.state.accountInfo.UserName, // 采购员
        PayablesAmount: "", // 应付金额
        SupplierName: "", // 供应商
        Contact: "", // 联系人
        PaidAmount: "", // 实付金额
        Address: "", // 地址
        Telephone: "", // 联系电话
        PaymentType: "", // 付款方式
        TaxRate: null, // 税点
        Remark: "", // 备注
        CurrencyCode: "RMB", // 币种
        ExchangeRate: "1", // 汇率
        DiscountAmount: null, // 折扣金额
        IsAdd: true, // 是否新增
        isAllowStorge: true, // 是否为保存并入库
        LineList: [], // 保存入库详情明细行
        PDID: "",
        CBGUID: "", // 收款账号,
        ExistPurchaseOrder: "",
        StkInGUID: "", // 入库单ID
        StkInID: "",
        ExpectPayDate: "",
        NeedDeliveryDate: "",
      };
      this.StkIn = _.extend(this.StkIn, info);
      this.itemSource = [];
    },
    LoadListByStock: async function (stkline, isChecklist) {
      // // 根据传入的stock对象加载明细列表
      this.StkIn.SupplierName = stkline[0].SupplierName;
      this.StkIn.Contact = stkline[0].Contact;
      this.StkIn.Telephone = stkline[0].Telephone;
      this.StkIn.TaxRate = stkline[0].TaxRate;
      this.getAddress(stkline[0]);
      // 初始化页面
      if (stkline.length > 0) {
        let rowID = 0;
        _.each(stkline, (stocks) => {
          let invQty = stocks.UsableQty;
          if (invQty < 0) {
            invQty = invQty * -1;
          }
          let price = stocks.BuyPrice;
          if (!this.hasValue(price)) {
            price = stocks.Price;
          }
          if (!this.hasValue(price)) {
            price = stocks.UnitPrice;
          }
          if (!this.hasValue(price)) {
            price = stocks.QuotePrice;
          }

          // 页面传递业务逻辑
          let inlinobj = {
            RowGuid: rowID++,
            StkGUID: stocks.StkGUID, // 库存GUID
            SPGUID: stocks.SPGUID, // 采购订货GUID
            SPID: stocks.SPID,
            IsDelete: false,
            ProductCategory: stocks.ProductCategory,
            ReplaceModel: stocks.ReplaceModel,
            Model: stocks.Model,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            TaxRate: stocks.TaxRate,
            Quality: stocks.Quality,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Partner: stocks.Partner,
            InvPosition: stocks.InvPosition,
            Remark: stocks.Remark,
            Remark1: stocks.Remark1,
            Remark2: stocks.Remark2,
            CBGUID: stocks.CBGUID,
            Qty:
              _.isUndefined(invQty) && !_.isUndefined(stocks.Qty)
                ? stocks.Qty
                : invQty,
            PriceNoTax: this.ConvertNumber(price), // 采购价(未税)
            Price: this.CalcPriceInTax(this.ConvertNumber(price)), // 采购价(含税)
            MarkingCode: stocks.MarkingCode,
            Amount: this.CalcAmout(
              invQty,
              this.CalcPriceInTax(this.ConvertNumber(price))
            ), // 总额
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            StorageName: stocks.StorageName, // 库位
            CustomerPartNo: stocks.CustomerPartNo, // 客户料号
            Place: stocks.Place,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [inlinobj];
          } else {
            this.itemSource.push(inlinobj);
          }
        });
        this.CalcPay(true);
        this.CalcProfitMargins();
        this.onBindData();
        this.ResTaxRate();
      } else {
        // 批次库存可不选库存直接添加 销售出库 和 采购入库
        // this.$message({ message: '加载库存失败，请刷新后重试', type: 'error' })
      }
    },

    // 从询价记录入库
    InquireModel: async function (rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stock, i) => {
          let invQty = stock.InvQty;
          if (stock.InvQty < 0) {
            invQty = -stock.InvQty;
          }
          // 判断是否是共享库存ID
          stock.Price = this.toFloat(stock.Price, 6);
          // if (!_.isNaN(stock.ShareGuid) || !_.isUndefined(stock.ShareGuid) || stock.ShareGuid !== null) {
          //   invQty = stock.ShareQty
          //   if (stock.ShareQty < 0) {
          //     invQty = -stock.ShareQty
          //   }
          //   stock.Price = this.toFloat(stock.SharePrice, 6)
          // }

          //税点
          this.StkIn.TaxRate = stock.TaxRate;
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            ProductCategory: stock.ProductCategory,
            Model: stock.Model,
            Brand: stock.Brand,
            Packaging: stock.Packaging,
            MakeYear: stock.MakeYear,
            MPQ: stock.MPQ,
            Quality: stock.Quality,
            Unit: !this.hasValue(stock.Unit) ? this.paramQtyUnit : stock.Unit,
            Partner: stock.Partner,
            InvPosition: stock.InvPosition,
            Remark: stock.Remark,
            Remark1: stock.Remark1,
            Remark2: stock.Remark2,
            Qty: invQty,
            PriceNoTax: this.CalcPrice(stock.Price), // 采购价(未税)
            Price: stock.Price, // 采购价(含税)
            MarkingCode: stock.MarkingCode,
            Amount: this.CalcAmout(invQty, stock.Price), // 总额
            InvType: !this.hasValue(stock.InvType)
              ? this.paramQtyType
              : stock.InvType, // 库存类型
            StorageName: stock.StorageName, // 库位
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
          this.StkIn.SupplierName = stock.SupplierName;
          this.StkIn.Contact = stock.Contact;
          this.StkIn.Telephone = stock.Telephone;

          this.StkIn.SupplierID = stock.SupplierID;
          this.getAddress(stock);
        });
        this.onBindData();
      } else {
        this.$message({ message: "加载库存失败，请刷新后重试", type: "error" });
      }
    },

    // 单元格结束触发事件
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      if (!this.hasValue(aftervalue)) {
        return;
      }
      if (!gridobj) return;
      let rowData = gridobj.row;

      /*************处理大小写配置start*************/
      //要处理大小写的列
      const handleColumns = ["Model", "Brand", "Packaging", "MakeYear"];
      if (
        !this.CompanySetup.EnableNotUpperCase &&
        handleColumns.includes(colname)
      ) {
        aftervalue = aftervalue.toUpperCase();
        rowData[colname] = aftervalue;
      }
      /*************处理大小写配置end***************/

      if (colname === "Model") {
        // 根据型号获取上次价格
        if (this.IsTakeOutPrint && this.hasValue(this.StkIn.SupplierName)) {
          // 未税算含税
          let data = _.extend(
            {},
            { Model: rowData.Model, SupplierName: this.StkIn.SupplierName }
          );
          this.$post(CONFIG.GetPrice, data, (data) => {
            let price = this.toFloat(data, 6); // 未税价格
            let priceintaxSummary = this.CalcPriceInTax(price);
            rowData["Price"] = price;
            rowData["PriceNoTax"] = priceintaxSummary;
          });
        }
      }
      if (colname === "Price") {
        // 含税算未税
        // 由含税计算出未税价
        let _priceNoTax = this.CalcPrice(aftervalue);
        rowData["PriceNoTax"] = _priceNoTax;
      }
      if (colname === "PriceNoTax") {
        // 未税算含税
        // 由未税计算出含税价
        let _price = this.CalcPriceInTax(aftervalue);
        rowData["Price"] = _price;
      }

      if (
        colname === "Price" ||
        colname === "PriceNoTax" ||
        colname === "Qty"
      ) {
        let numbers = this.toFloat(rowData["Qty"]);
        let price = this.toFloat(rowData["Price"]);
        rowData["Amount"] = this.CalcAmout(numbers, price);
        this.CalcPay(false);
        this.CalcProfitMargins();
      }

      //判断是否为最后一行，并且“新增一行”按钮启用，自动添加空行
      if (
        rowindex == gridobj.rows.length - 1 &&
        !this.isDisabled &&
        this.isAllow
      ) {
        this.$refs.flexGrid.addRow(true);
      }
    },
    getColumn(name) {
      let list = [{ name: "_Checked" }];
      let columns = this.columns;
      _.each(columns, (item) => {
        if (item.visible) {
          list.push(item);
        }
      });
      for (let i = 0; i < list.length; i++) {
        if (name === list[i].name) {
          return {
            col: columns[i],
            index: i,
          };
        }
      }
    },
    setCellData(r, c, v) {
      this.$nextTick(() => {
        let trs = this.$el.querySelectorAll(
          ".el-table .el-table__body-wrapper .el-table__body tbody tr"
        );
        let containerOld =
          trs[r] && trs[r].querySelectorAll("td")[c].querySelector(".cell div");
        let containerNew =
          trs[r] &&
          trs[r].querySelectorAll("td")[c].querySelector(".cell input");
        if (containerOld) {
          containerOld.innerText = v;
        } else {
          if (containerNew) {
            containerNew.value = v;
          }
        }
      });
    },
    paymentTypeChange(val) {
      this.StkIn.PaymentType = val === undefined ? "" : val.key;
      if (this.StkIn.PaymentType === "现金") {
        this.$message({
          message: "付款方式:【现金】请输入实付金额.",
          type: "warning",
        });
        this.StkIn.PaidAmount =
          this.StkIn.PayablesAmount -
          this.ConvertNumber(this.StkIn.DiscountAmount);
      } else {
        this.StkIn.PaidAmount = "";
      }
    },
    discountChange(val) {
      if (val) {
        if (this.StkIn.PaymentType === "现金") {
          this.StkIn.PaidAmount =
            this.StkIn.PayablesAmount -
            this.ConvertNumber(this.StkIn.DiscountAmount);
        } else {
          this.StkIn.PaidAmount = "";
        }
      }
    },
    CalcTaxRate(val) {
      // PriceNoTax 未税
      // Price 含税
      // Qty 数量
      // Amount 小计金额
      val ? (this.StkIn.TaxRate = val.key) : (this.StkIn.TaxRate = null);
      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].PriceNoTax)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].PriceNoTax);
            source[i].Price = _priceIntax;
          } else if (this.hasValue(source[i].Price)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].Price);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].Price
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcPay(false);
      }
    },
    CalcProfitMargins() {
      // 入库单没有 计算利润率
      // let tempSource = this.itemSource
      // if (tempSource.length > 0) {
      //   for (let i = 0; i < tempSource.length; i++) {
      //     // 利润率=(销售定价-未税单价)/未税单价 * 100. 显示加上% ， 保留两位小数
      //     let salesPrice = this.toFloat(tempSource[i].SalesPrice)
      //     let priceNoTax = this.toFloat(tempSource[i].PriceNoTax)
      //     if (salesPrice > 0 && priceNoTax > 0) {
      //       salesPrice = (salesPrice - priceNoTax) / priceNoTax * 100
      //       tempSource[i].ProfitMargins = this.toFloat(salesPrice, 2) + '%'
      //     } else {
      //       tempSource[i].ProfitMargins = ''
      //     }
      //   }
      // }
    },
    SearchCompany() {
      // 弹出公司搜索页面
      this.InTypes = "2,3"; // 供应商和供应商客户
      this.dialogCompanySelectVisible = true;
      this.SelectSearchModel.CompanyName = this.StkIn.SupplierName;
    },
    companyChecked(item) {
      this.getAddress(item);
      // 将回传的数据进行页面赋值
      if (this.hasValue(item.CCompanyID)) {
        this.StkIn.SupplierID = item.CCompanyID;
      }
      if (this.hasValue(item.CompanyName)) {
        this.StkIn.SupplierName = item.CompanyName;
      }

      if (this.hasValue(item.ContactName)) {
        this.StkIn.Contact = item.ContactName;
      }
      if (this.hasValue(item.Telephone)) {
        this.StkIn.Telephone = item.Telephone;
      }
      // 默认跟进人，跟进人为空，默认当前登录用户 ,跟进人为多个取第一个。
      const regex = /,|，/; // 逗号
      if (this.hasValue(item.FollowUserName)) {
        let idList = item.FollowUserName.split(regex);
        let followUserName = "";
        if (idList.length > 1) {
          followUserName = idList[0];
        } else {
          followUserName = item.FollowUserName;
        }
        if (this.hasValue(followUserName)) {
          let info = _.find(this.selectOptionsAllUserURL, (item) => {
            return item.value === followUserName;
          });
          if (this.hasValue(info) && this.hasValue(info.key)) {
            this.StkIn.ReceiveUserID = info.key;
            this.StkIn.Receiver = info.value;
          }
        }
      }
      if (!this.hasValue(this.StkIn.ReceiveUserID)) {
        this.StkIn.ReceiveUserID = this.$store.state.accountInfo.UserID;
        this.StkIn.Receiver = this.$store.state.accountInfo.UserName;
      }

      if (this.hasValue(item.PaymentType)) {
        this.StkIn.PaymentType = item.PaymentType;
      }
      if (this.hasValue(item.CurrencyCode)) {
        this.StkIn.CurrencyCode = item.CurrencyCode;
      }
      this.CurrencyCodeChange();

      if (this.hasValue(item.TaxRate) && this.StkIn.TaxRate !== item.TaxRate) {
        this.StkIn.TaxRate = item.TaxRate;
        this.CalcTaxRate({ key: item.TaxRate });
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    onbuyerChange() {
      let buyer = _.find(this.selectOptionsAllUserURL, (item) => {
        return item.key === Number(this.StkIn.ReceiveUserID);
      });
      if (!_.isUndefined(buyer)) {
        this.StkIn.Receiver = buyer.value;
      }
    },
    onBillIconClick() {
      if (this.StkIn.IsAdd) {
        this.IsManual = !this.IsManual;
        if (!this.IsManual) {
          this.StkIn.BillNo = "";
        }
      } else {
        this.$message({ message: "单据编号生成后不可以修改！", type: "error" });
      }
    },
    hasSpecialRes(code) {
      return _.some(
        this.selectOptionsSpecialResource,
        (page) => page.Code === code
      );
    },
    autocompleteChange(val) {
      this.startTime = new Date();
      _.delay(() => {
        this.endTime = new Date();
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val);
        }
      }, 800);
    },
    autocompleteSearch(isSearch, data) {
      if (!isSearch) return;
      this.autocompleteSearchData = data;
      if (data.length > 0) {
        // this.autocompleteShow = true
        this.$refs.autocompleteSelect.$el.style.left =
          getabsleft(this.$refs.znlAutocomplete.$el) + 65 + "px";
        this.$refs.autocompleteSelect.$el.style.top =
          getabstop(this.$refs.znlAutocomplete.$el) + 30 + "px";
      } else {
        this.autocompleteShow = false;
      }
    },
    selectionChanged(val) {
      this.autocompleteShow = false;
      this.companyChecked([val]);
    },
    // 连续录单模式保存到参数配置
    onContinuousRecordValue() {
      // _.each(this.ParamJosn, item => {
      //   if (item.ParamCode === 'IsContinuousRecord') {
      //     item.ParamValue = this.IsContinuousRecord ? 1 : 0
      //   }
      // })
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsContinuousRecord ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkIn");
          }
        );
      }
    },
    getAddress(item) {
      this.selectOptionsAddress = [];
      // 获取地址信息组合下拉框数据
      if (this.hasValue(item.DeliveryAddress)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.DeliveryAddress,
          value: item.DeliveryAddress,
        });
      }
      if (this.hasValue(item.Address)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address,
          value: item.Address,
        });
      }
      if (this.hasValue(item.Address2)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address2,
          value: item.Address2,
        });
      }
      if (this.hasValue(item.Address3)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address3,
          value: item.Address3,
        });
      }
      // 获取地址信息 如果存在货运地址默认货运地址
      if (this.hasValue(item.DeliveryAddress)) {
        this.StkIn.Address = item.DeliveryAddress;
      }
      // 获取地址信息 如果没有货运地址默认公司地址
      if (!this.hasValue(item.DeliveryAddress) && this.hasValue(item.Address)) {
        this.StkIn.Address = item.Address;
      }
      // 获取地址信息 如果公司地址地址默认Address1
      if (!this.hasValue(item.Address) && this.hasValue(item.Address2)) {
        this.StkIn.Address = item.Address2;
      }
      // 获取地址信息 如果没有Address1默认Address2
      if (!this.hasValue(item.Address2) && this.hasValue(item.Address3)) {
        this.StkIn.Address = item.Address3;
      }
      // 如果没有地址信息，清空上次输入的地址信息
      if (
        !this.hasValue(item.Address) &&
        !this.hasValue(item.Address2) &&
        !this.hasValue(item.Address3) &&
        !this.hasValue(item.DeliveryAddress)
      ) {
        this.$refs.Address.clear();
      }
    },
    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkIn");
          }
        );
      }
    },
    changeAddressInputHandler(value) {
      const selectValue = value.target.value;
      let addres = _.find(this.selectOptionsAddress, function (item) {
        return item.key === selectValue;
      });
      if (!this.hasValue(addres) && _.trim(selectValue) !== "") {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: selectValue,
          value: selectValue,
        });
      }
      this.StkIn.Address = selectValue;
    },

    // 将数据添加到数据源
    addRowToItemSource(rows) {
      let list = [];
      _.each(rows, (item) => {
        this.StkInLineID--;
        this.rowID++;
        let invQty = item.InvQty;
        if (item.InvQty < 0) {
          invQty = -item.InvQty;
        }
        item.PriceNoTax = this.ConvertNumber(item.BuyPrice);
        item.Price = this.CalcPriceInTax(this.ConvertNumber(item.BuyPrice)); // 含税价
        item.Amount = this.CalcAmout(
          this.ConvertNumber(invQty),
          this.ConvertNumber(item.BuyPrice)
        );
        let row = _.extend({}, item, {
          StkInLineID: this.StkInLineID,
          RowGuid: this.rowID,
          InvType: !this.hasValue(item.InvType)
            ? this.paramQtyType
            : item.InvType, // 库存类型
          Unit: !this.hasValue(item.Unit) ? this.paramQtyUnit : item.Unit, // 单位
        });
        list.push(row);

        // 供应商名称
        if (item.SupplierName && !this.hasValue(this.StkIn.SupplierName)) {
          this.StkIn.SupplierName = item.SupplierName;
        }
      });
      if (this.itemSource && this.itemSource.length > 0) {
        let info = this.itemSource[this.itemSource.length - 1];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
        }
      }
      this.itemSource = this.itemSource.concat(list);
      this.CalcPay(false);
    },

    subStrStkValue(value) {
      let newValue = "";
      if (!this.hasValue(value)) return;
      if (value.indexOf("颜色分类:") > -1) {
        newValue = value.substr(5);
      } else if (value.indexOf("颜色:") > -1) {
        newValue = value.substr(3);
      }
      return newValue;
    },
    // 从淘宝订单生成入库单
    loadByTaobao(rows) {
      if (rows === undefined || rows === null || rows.length <= 0) {
        return;
      }
      _.each(rows, (row) => {
        // 明细
        if (!this.hasValue(row.Orders)) {
          return;
        }
        let lines = JSON.parse(row.Orders);
        if (lines === undefined || lines === null || lines.length <= 0) {
          return;
        }
        _.each(lines, (item) => {
          let invQty = this.ConvertNumber(item.Num);
          let model = "";
          if (this.hasValue(item.SkuPropertiesName)) {
            model = this.subStrStkValue(item.SkuPropertiesName);
          } else {
            model = item.Title;
          }
          let lineItem = {
            Model: model,
            Qty: invQty,
            OidStr: item.OidStr,
            TidStr: row.TidStr,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [lineItem];
          } else {
            this.itemSource.push(lineItem);
          }
        });
      });
      this.StkIn.IsAdd = true;
      // this.StkIn.NewSupplierLabel = '淘宝'
    },

    // 月结服务勾选
    onLoanServiceChange() {
      if (this.useLoanService) {
        this.StkIn.TaxRate = 13;
        this.$message({ message: "税点已改为13" });
      }
    },
    //进口报关
    onImportDeclaration() {
      //打开供应链
      openSCM({ key1: 1, key2: "test" });
      try {
        var param = {
          collectionType: "供应链入口",
          eventName: "编辑入库单-进口报关",
        };

        //记录日志
        this.$post(CONFIG.AddInfoCollectLogUrl, param, (data, logic) => { });
      } catch (error) {
        console.log("进口报关记录失败:", error);
      }
    },
  },
};
</script>
<style lang="scss">
.dialog-znl-stkinedit {
  > .znl-dialog {
    max-height: 1024px !important;
  }
  .znl-dialog__body {
    overflow: auto;
  }
  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 15px 30px;
  }
  .stkinedit-min-grid-box .has-gutter tr th {
    background: #ebeef5;
  }
  .znl-dialog.el-dialog .el-dialog__header {
    height: auto;
  }
  .znl-dialog {
    max-height: 900px !important;
  }

  .el-select-transparent {
    background-color: transparent;

    .el-input__inner {
      background-color: transparent;
    }

    .el-input__suffix {
      right: 1px;
    }
    i {
      color: red;
      background-color: #fff;
      border-radius: 8px;
      width: 16px;
      height: unset;
      line-height: 16px;
    }
  }
}
</style>

